import styles from "../PriceOffer/PriceOffer.module.scss"
import face from "../../images/black/facial-treatment-black.svg"
import eyes from "../../images/black/tweezers-black.svg"
import feet from "../../images/black/pedicure-black.svg"
import nails from "../../images/black/manicure-black.svg"
import { PRICELIST } from "./pricelist"
const PriceOffer = () => {
  const Nails = PRICELIST.nails
  const Feet = PRICELIST.feet
  const Face = PRICELIST.face
  const Eyes = PRICELIST.eyes
  const Waxing = PRICELIST.waxing
  const Makeup = PRICELIST.makeup

  return (
    <div className={styles.wrapper, styles.background_feather}>
      <div className={styles.priceOffer}>
        <span id="maniucure"></span>
        <div className={styles.category}>
          <div className={styles.firstCategoryTitle}>
            <img src={nails} alt="maniucure"></img>
            <h3>Manicure</h3>
          </div>
          {Nails.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
                <p>{item.time}</p>
              </div>
            </div>
          ) )}
        </div>
        <div className={styles.category} >
          <div className={styles.categoryTitle}>
            <span id="pedicure"></span>
            <img src={feet} alt="pedicure"></img>
            <h3>pedicure</h3>
          </div>
          {Feet.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
                <p>{item.time}</p>
              </div>
            </div>
          ) )}
        </div>
        <div className={styles.category}>
          <div className={styles.categoryTitle}>
            <span id="face-treatments"></span>
            <img src={face} alt="Zabiegi na twarz"></img>
            <h3>Zabiegi na twarz</h3>
          </div>
          {Face.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className={styles.vl}> </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
                <p>{item.time}</p>
              </div>
            </div>
          ) )}
        </div>
        <div className={styles.category}>
          <div className={styles.categoryTitle}>
            <span id="eyes-tretments"></span>
            <img src={eyes} alt="Zabiegi okolic oczu"></img>
            <h3>Zabiegi okolic oczu</h3>
          </div>
          {Eyes.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
                <p>{item.time}</p>
              </div>
            </div>
          ) )}
        </div>
        <div className={styles.category}>
          <div className={styles.categoryTitle}>
            <span id="waxing"></span>
            <img src={eyes} alt="woskowanie"></img>
            <h3>Depilacja woskiem</h3>
          </div>
          {Waxing.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
              </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
              </div>
            </div>
          ) )}
        </div>
        <div className={styles.category}>
          <div className={styles.categoryTitle}>
            <span id="makeup"></span>
            <img src={eyes} alt="makeup"></img>
            <h3>Makeup</h3>
          </div>
          {Makeup.map( ( item ) => (
            <div className={styles.categoryDetails}>
              <div className={styles.categoryInfo}>
                <h4>{item.name}</h4>
                <p>{item.description}</p>
              </div>
              <div className={styles.categoryPrice}>
                <p>{item.price}</p>
              </div>
            </div>
          ) )}
        </div>
      </div>
    </div>
  )
}

export default PriceOffer
