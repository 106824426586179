import { Button } from "../../components/Button/Button"
import { Link } from "react-router-dom"
import styles from './Header.module.scss'
import Menu from '../Menu/Menu'

const Header = () => {
  return (
    <div className={styles.headerSection} id="home">
      <div className={styles.background}>
        <Menu></Menu>
        <div className={styles.wrapper}>
          <div className={styles.header}>
            <h1>Studio Urody <strong>Monika Pióro</strong></h1>
            <p>Znajdziesz u nas wszystko co najlepsze do Twojej skóry, dłoni i paznokci.</p>
            <div className={styles.buttons}>
              <a href="tel:798860862"><Button clasName={styles.headerButton} title='Zarezerwuj wizytę' /></a>
              <Link to="/oferta"><Button clasName={styles.headerButton} title='Poznaj ofertę' /></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
