import React from "react";
import styles from "./ImagesList.module.scss";
import { imagesData } from "./ImagesData";

const ImagesList = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.imagesSection}>
        <div>
          <h3 className={styles.title}>Zdjęcia</h3>
        </div>
        <div className={styles.imagesWrapper}>
          {imagesData.map((tile) => (
            <div className={styles.images}>
              <img
                src={tile.img}
                alt={tile.title}
                width={tile.width}
                height={tile.height}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ImagesList;
