import React from 'react'
import Card from "./Card"
import styles from './Offers.module.scss'
import face from "../../images/black/facial-treatment-black.svg"
import eyes from "../../images/black/tweezers-black.svg"
import feet from "../../images/black/pedicure-black.svg"
import nails from "../../images/black/manicure-black.svg"
import depilation from "../../images/black/cream-black.svg"
import cosmetics from "../../images/black/lipgloss-black.svg"

const Offers = () => {
  const offers = {
    manicure: {
      img: nails,
      title: "Maniucure",
      description: "Pielęgnacja dłoni."
    },
    pedicure: {
      img: feet,
      title: "Pedicure",
      description: "Pielęgnacja stóp."
    },
    face: {
      img: face,
      title: "Pielęgnacja twarzy",
      description: "Zadbaj o kondycję skóry swojej twarzy. "
    },
    makeup: {
      img: cosmetics,
      title: "Makeup",
      description: "Makijaż wieczorowy oraz makijaż permanentny brwi."
    },
    wax: {
      img: depilation,
      title: "Depilacja woskiem",
      description: "Deplikacja woskiem całego ciała."
    },
    eyes: {
      img: eyes,
      title: "Zabiegi okolic oczu",
      description: "Laminacja rzęs, przedłużanie."
    }
  }

  return (
    <div className={styles.offersWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.offers} id="offers">
          <h2> Nasza Ofera</h2>
          <div className={styles.offers_box}>
            <div>
              <a href="/oferta#maniucure"> <Card image={offers.manicure.img} title={offers.manicure.title} description={offers.manicure.description}></Card></a>
            </div>
            <div>
              <a href="/oferta#pedicure"> <Card image={offers.pedicure.img} title={offers.pedicure.title} description={offers.pedicure.description}></Card></a>
            </div>
            <div >
              <a href="/oferta#makeup"><Card image={offers.makeup.img} title={offers.makeup.title} description={offers.makeup.description}></Card> </a>
            </div>
          </div>
          <div className={styles.offers_box}>
            <div>
              <a href="/oferta#face-treatments"><Card image={offers.face.img} title={offers.face.title} description={offers.face.description}></Card></a>
            </div>
            <div>
              <a href="/oferta#eyes-tretments"><Card image={offers.eyes.img} title={offers.eyes.title} description={offers.eyes.description}></Card> </a>
            </div>
            <div>
              <a href="/oferta#waxing"><Card image={offers.wax.img} title={offers.wax.title} description={offers.wax.description}></Card> </a>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Offers
