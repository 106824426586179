import React from "react"
import styles from "./Contact.module.scss"
import { Button } from "../../components/Button/Button"

const Contact = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.contact_box} id="contact">
          <h2> Kontakt</h2>
          <div className={styles.contact}>
            <div className={styles.information}>
              <h3>Studio Urody Monika Pióro</h3>
              <p>Znajdziesz u nas wszystko co najlepsze do Twojej skóry, dłoni i paznokci</p>
              <a href="tel:798860862"> <Button title="Umów wizytę" /> </a>
            </div>
            <div className={styles.address}>
              <h3>Lokalizacja:</h3>
              <p>C.H Kociewie </p>
              <p>ul. Lubichowska 14</p>
              <p>83-200 Starogard Gdański</p>
              <a href="mailto:kosm.monika@gmail.com">Email: kosm.monika@gmail.com</a>
              <a href="tel:798860862">Tel. 798-860-862</a>

            </div>
            <div className={styles.openHours}>
              <h3>Godziny otwarcia: </h3>
              <p>Poniedziałek - Piątek 10:00 - 18:00 </p>
              <p>Sobota 10:00 - 14:00</p>
              <p>Niedziela - nieczynne</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.implementation}>
      <span><b>Wykonanie: </b><a target="_blank"rel="noreferrer" href="https://paulakreft.github.io/paulinaweb/">Paulina Kreft</a></span>
      </div>
    </>
  )
}
export default Contact
