import './App.css';
import Home from "./pages/Home"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import OffersPage from "./pages/Offers";
import TermsPage from "./pages/TermsPage";
import Images from "./pages/Images"
function App() {
  return (
    <Router>
      <div className="App">
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route path="/oferta" component={OffersPage}></Route>
          <Route path="/regulamin" component={TermsPage}></Route>
          <Route path="/zdjecia" component={Images}></Route>
        </Switch>
      </div >
    </Router>

  );
}

export default App;
