import styles from "./Terms.module.scss";

const Terms = () => {
  return (
      <div className={styles.terms}>
         <div className={styles.wrapper, styles.background_feather}>
        <h1 className={styles.termsHeader}>
          Regulamin Studia Urody Monika Pióro
        </h1>
        <h3>Szanowni Klienci!</h3>
        <p>
          W trosce o bezpieczeństwo i zdrowie Wasze i naszych pracowników, a
          także w celu stałego podwyższania jakości świadczonych usług prosimy o
          zapoznanie się z regulaminem świadczenia usług kosmetycznych w naszym
          salonie przed przystąpieniem do korzystania z nich . Skorzystanie z
          oferty Salonu Kosmetycznego oznacza akceptację regulaminu bez
          konieczności jego podpisania.
        </p>
        <div className={styles.paragraph}>
          <h3>§ 1. Godziny otwarcia</h3>
          <ol>
            <li>
              Studio Urody otwarte jest od poniedziałku do piątku w godzinach od
              10:00 do 18:00, soboty od 10.00 do 14:00 , niedziele i święta
              nieczynne.
            </li>
            <li>
              Zmiany w godzinach otwarcia salonu mogą ulec zmianie z przyczyn
              niezależnych od nas: absencji pracownika lub losowych.
            </li>
          </ol>
        </div>

        <div className={styles.paragraph}>
          <h3>§ 2. Umawianie na wizyty</h3>
          <ol>
            <li>
              Prosimy o szczegółowe planowanie swojej wizyty tj. zabiegu
              głównego i towarzyszących mu drobnych usług takich jak: depilacja
              wąsika, regulacja brwi, depilacja pach, henna, ściągnięcie hybrydy
              i inne. Spełnimy chętnie wszystkie życzenia pod warunkiem, że
              będziemy mieć wystarczającą ilość czasu, aby następny klient nie
              musiał czekać. Na wizycie wykonujemy tylko zaplanowane usługi i
              obsługujemy tylko zapisane osoby.
            </li>
          </ol>
        </div>

        <div className={styles.paragraph}>
          <h3>§ 3. Punktualne rozpoczynanie zabiegów</h3>
          <ol>
            <li>
              Klienci umawiani są na konkretne godziny, prosimy o punktualność.
            </li>
            <li>
              W wyjątkowych sytuacjach dopuszczalne jest spóźnienie klienta na
              zabieg max.15min.
            </li>
            <li>
              O spóźnieniu prosimy informować telefonicznie niezwłocznie przed
              planowaną godziną rozpoczęcia zabiegu.
            </li>
            <li>
              W przypadku znacznego spóźnienia (powyżej 30 minut) klient musi
              liczyć się z odmową wykonania zabiegu w danym dniu i ustaleniem
              nowego terminu.
            </li>
            <li>
              W wyjątkowych sytuacjach obsługa w Studio Urody dopuszcza okres
              oczekiwania klienta w poczekalni do 15 minut.
            </li>
          </ol>
        </div>

        <div className={styles.paragraph}>
          <h3>§ 4. Odwoływanie wizyt i nieprzychodzenie na umówiony termin</h3>
          <ol>
            <li>
              W razie rezygnacji z wizyty należy powiadomić obsługę Studia Urody
              nie później niż 48 h przed planowaną wizytą. Na liście rezerwowej
              oczekują osoby, które z chęcią przyjdą na zwolniony termin, pod
              warunkiem, że ich również odpowiednio wcześniej powiadomimy.
            </li>
          </ol>
        </div>
        <div className={styles.paragraph}>
          <h3>§ 5. Wizyty w towarzystwie</h3>
          <ol>
            <li>
              Na terenie Salonu Kosmetycznego obowiązuje całkowity zakaz
              wprowadzania zwierząt.
            </li>
            <li>
              Na terenie studia obowiazuje całkowity zakaz palenia tytoniu.
            </li>
            <li>
              Klientka badź klient ponosi odpowiedzialność finansową za świadome
              uszkodzenie badż zniszczenie wyposażenia w Studio Urody Monika
              Pióro
            </li>
            <li>
              Salon nie ponosi odpowiedzialności za rzeczy wartościowe
              pozostawione w miejscach bez nadzoru.
            </li>
          </ol>
        </div>
        <div className={styles.paragraph}>
          <h3>§ 6. Formularze, kartoteki i poufność informacji</h3>
          <ol>
            <li>
            Studio Urody Monika Pióro  działa zgodnie z ustawą o ochronie danych osobowych. Państwa dane nigdy nie zostaną udostępnione osobom trzecim.
            </li>
            <li>
            Danych używamy wyłącznie do kontaktu z Państwem i gwarantujemy ich nienadużywanie, zarówno w przypadku SMS, jak i mailingu.
            </li>
          </ol>
        </div>
        <div className={styles.paragraph}>
          <h3>§ 7. Reklamacje</h3>
          <ol>
            <li>
            W przypadku niezadowolenia z naszych usług bardzo prosimy o poinformowanie kierowniczkę Studia Urody Monika Pióro abyśmy mogli naprawić sytuację.
            </li>
            <li>
            Bierzemy pełną odpowiedzialność za nasze działania i oferujemy jakość usługi na najwyższym poziomie.
            </li>
            <li>
            Klienci powinni zgłaszać reklamację niezwłocznie po wystąpieniu niepożądanych objawów, nie później niż do 3 dni od wykonania zabiegu.
            </li>
            <li>
            Klienci wykonujący manicure/pedicure mogą zgłaszać reklamację do 5 dni, po tym terminie nie zostanie ona uwzględniona.
            </li>
          </ol>
        </div>

        <div className={styles.paragraph}>
          <h3>§ 8. Lista rezerwowa</h3>
          <ol>
            <li>
            Oferujemy możliwość wpisania się na listę rezerwową.
            </li>
            <li>
            W przypadku zwolnienia terminu na dany zabieg, zawsze kontaktujemy się z pierwszą osobą na liście.
            </li>
          </ol>
        </div>

        <div className={styles.paragraph}>
          <h3>§ 9. Standard obsługi</h3>
          <ol>
            <li>
            Wszystkie procedury, zapisy oraz obsługa przed, w trakcie i po zabiegu prowadzone są z najwyższą starannością o komfort i bezpieczeństwo klienta.
            </li>
            <li>
            W Studio Urody Monika Pióro zapewniamy:
            </li>
            <ol className={styles.alpha}>
              <li>bezpieczny atestowany i serwisowany sprzęt i urządzenia,</li>
              <li>zdezynfekowane powierzchnie i sterylne narzędzia zabiegowe,</li>
              <li>profesjonalny dobór pielęgnacji domowej dla intensyfikacji efektów zabiegów,</li>
              <li>ściśle wyselekcjonowany i wykształcony personel,</li>
              <li>atmosferę bezpieczeństwa i opieki,</li>
              <li>pełną dyskrecję i wysoką kulturę osobistą pracowników,</li>
              <li>pełną odpowiedzialność za nasze działania zabiegowe,</li>
              <li>szacunek i spełnianie Państwa niestandardowych życzeń – oczywiście w miarę możliwości.</li>

            </ol>
            
          </ol>
        </div>


      </div>
    </div>
  );
};

export default Terms;
