import manicure from "../../images/gallery/manicure.jpg";
import nails from "../../images/gallery/nails.jpg";
import nailsRed from "../../images/gallery/nailsRed.jpg";
import nailsPink from "../../images/gallery/nailspink.jpg";
import treatments from "../../images/gallery/treatments.jpg";
import salon from "../../images/gallery/salon.jpg";
import mon_permanent from "../../images/gallery/mon_permanent.jpg";
import mon_eyes from "../../images/gallery/mon_eyes.jpg";
import manicureset from "../../images/gallery/manicureset.jpg"
import makeup from "../../images/gallery/makeup.jpg"
import lashes from "../../images/gallery/iza_lashes.jpg"
import monNails from "../../images/gallery/mon_nails.jpeg"
import permanent_monika from "../../images/gallery/monika_permanent.jpg"
import permanent_eyebrows from "../../images/gallery/permanent_eyebrows.jpg"
import feathers from "../../images/gallery/feathers.jpeg"
import salon_view from "../../images/gallery/salon_view.jpeg"
import flowers from "../../images/gallery/flowers.jpeg"

export const imagesData = [

  {
    img: mon_permanent,
    title: "Makijaz permanentny",
    width: "300px",
    height: "350px",
  },
  {
    img: mon_eyes,
    title: "Makijaz",
    width: "300px",
    height: "350px",
  },
  {
    img: monNails,
    title: "Pedicure",
    width: "300px",
    height: "350px",
  },
  {
    img: makeup,
    title: "Makeup",
    width: "300px",
    height: "350px",
  },
  {
    img: lashes,
    title: "Granatowe paznokcie",
    width: "300px",
    height: "350px",
  },
  {
    img: treatments,
    title: "Granatowe paznokcie",
    width: "300px",
    height: "350px",
  },
  {
    img: manicure,
    title: "Manicure",
    width: "300px",
    height: "350px",
  },
  {
    img: nailsPink,
    title: "Pomalowane paznokcie",
    width: "300px",
    height: "350px",
  },
  {
    img: nailsRed,
    title: "Czerwone paznokcie",
    width: "300px",
    height: "350px",
  },
  {
    img: nails,
    title: "Granatowe paznokcie",
    width: "300px",
    height: "350px",
  },
  {
    img: salon,
    title: "Salon",
    width: "300px",
    height: "350px",
  },
  {
    img: manicureset,
    title: "Salon",
    width: "300px",
    height: "350px",
  },
  {
    img: flowers,
    title: "flowers",
    width: "300px",
    height: "350px",
  },
  {
    img: feathers,
    title: "feathers",
    width: "300px",
    height: "350px",
  },
  {
    img: salon_view,
    title: "Salon",
    width: "300px",
    height: "350px",
  },
];

export const permanentMakeUpImages = [
  {
    img: permanent_monika,
    title: "Makijaz",
    width: "250px",
    height: "auto",
  },
  {
    img: mon_permanent,
    title: "Makijaz permanentny",
    width: "250px",
    height: "auto",
  },
  {
    img: mon_eyes,
    title: "Makijaz",
    width: "250px",
    height: "auto",
  },
  {
    img: permanent_eyebrows,
    title: "Makijaz",
    width: "250px",
    height: "auto",
  },
]
