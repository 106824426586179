import React from 'react';
import ImagesList from "../sections/Images/ImagesList"
import Menu from "../sections/Menu/Menu";
import Contact from "../sections/Contact/Contact";

const Images = () => {
  return (
    <div>
      <Menu></Menu>
      <ImagesList></ImagesList>
      <Contact></Contact>
    </div>
  );
}

export default Images;
