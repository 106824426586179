import React, { useState } from "react"
import styles from './Menu.module.scss'
import menuOpen from '../../images/bars-solid.svg'
import menuClose from '../../images/times-solid.svg'
import facebook from '../../images/facebook-brands.svg'
import instagram from '../../images/instagram-brands.svg'
import { Link } from "react-scroll"
import { Link as NavLink } from "react-router-dom"

const Menu = () => {
  const [navbarOpen, setNavbarOpen] = useState( false )
  const [navbar, setNavbar] = useState( false )

  const handleToggle = () => {
    setNavbarOpen( !navbarOpen )
  }
  const closeMenu = () => {
    setNavbarOpen( false )
  }
  const showProperMenuIon = navbarOpen
    ? <img src={menuClose} alt="Menu" />
    : <img src={menuOpen} alt="Menu" />


  const showMenuItems = navbarOpen
    ? ( styles.navigationItems )
    : ( styles.disable )

  const changeBackground = () => {
    if ( window.scrollY >= 300 ) {
      setNavbar( true )
    } else {
      setNavbar( false )
    }
  }
  window.addEventListener( 'scroll', changeBackground )
  const useProperClass = navbar ? ( styles.wrapperNavActive ) : ( styles.wrapperNav )
  return (
    <nav>
      <ul className={styles.navigation}>
        <div className={styles.socialNav}>
          <div className={useProperClass}>
            <div className={styles.menuIcon} onClick={handleToggle}>
              {showProperMenuIon}
            </div>
            <div className={styles.socialIcons}>
              <a href="https://www.facebook.com/Studio-Urody-Monika-Pi%C3%B3ro-227198870768700/?ref" target='_blank'><img src={facebook} alt="Facebook" /></a>
              <a href='https://www.instagram.com/studio_urody_monika_pioro/' target='_blank'><img src={instagram} alt="Instagram" /></a>
            </div>
          </div>
          <div className={showMenuItems}>
            <Link onClick={closeMenu} activeClass="active" to="home" smooth={true} >
              <NavLink onClick={closeMenu} to="/">Strona główna</NavLink>
            </Link>
            <Link onClick={closeMenu} activeClass="active" to="about_us" smooth={true} offset={-100} duration={500}  >
              <NavLink onClick={closeMenu} to="/#about_us">O nas</NavLink>
            </Link>
            <Link onClick={closeMenu} activeClass="active" to="offers" smooth={true} offset={-100} duration={500}  >
              <NavLink onClick={closeMenu} to="/#offers"> Oferta</NavLink>
            </Link>
            <Link onClick={closeMenu} activeClass="active" to="contact" smooth={true} offset={-100} duration={500} >
              <NavLink onClick={closeMenu} to="/#contact"> Kontakt</NavLink>
            </Link>
            <Link onClick={closeMenu} activeClass="active">
              <NavLink onClick={closeMenu} to="/zdjecia"> Galeria</NavLink>
            </Link>
            <Link onClick={closeMenu} activeClass="active">
              <NavLink onClick={closeMenu} to="/regulamin"> Regulamin</NavLink>
            </Link>

          </div>
        </div>
      </ul>
    </nav >
  )
}
export default Menu
