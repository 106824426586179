import Contact from "../sections/Contact/Contact"
import Menu from '../sections/Menu/Menu'
import Terms from "../sections/Terms/Terms"

const TermsPage = () => {
  return(
    <div>
      <Menu></Menu>
      <Terms></Terms>
      <Contact></Contact>
    </div>
  
  )
}

export default TermsPage
