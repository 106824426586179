import React, { useState } from "react";
import menuClose from '../../images/times-solid.svg';
import styles from './Modal.module.scss';

const Modal = (open) => {
  const [modalOpen, setModalOpen] = useState( false )


  const hideModal = modalOpen ? "none" : "block"

  const handleOpenModal = () => {
  setModalOpen( true )
 }
  return (
    <div onClick={handleOpenModal} className={styles.modalBox} style={{"display": hideModal}}>
      <div ><img src={menuClose}alt="Close" /></div>
      <p>
        Makijaż Permanentny Brwi to coraz częściej wykonywany zabieg w naszym Gabinecie. Dokladnie ponad rok temu zaczęła się moja przygoda z makijażem permanentnym. 
      </p>
      <p>
        Wszyscy którzy już mnie poznali dokladnie wiedzą, że najważniejsze dla mnie jest zachowane naturalnego wyglądu brwi. 
      </p>
      <p>  
        Dlatego też metodą która pracuje charakteryzuje się osiagnietym efektem tak naturalnym, że trudno stwierdzić że zostały wykreowane przez linergistke a nie są dziełem natury. 
      </p>
      <p>
        Jeśli jesteś zainteresowana, ale nie wiesz do końca czy to jest zabieg dla Ciebie, zadzwoń i umów się na bezpłatna konsultacje. 
      </p>
      <p>Zapraszam,</p>
        
       <p> Monika<a href="tel:798860862">798860862</a></p>
    </div>
  );
}

export default Modal;
