import Contact from "../sections/Contact/Contact"
import Menu from '../sections/Menu/Menu'
import PriceOffer from "../sections/PriceOffer/PriceOffer"

const OffersPage = () => {
  return (
    <div>
      <Menu></Menu>
      <PriceOffer></PriceOffer>
      <Contact></Contact>
    </div>

  )
}
export default OffersPage
