import izabela from "../../images/izabela.jpg"
import monika from "../../images/monikaPioro.jpeg"
import styles from './AboutUs.module.scss'
const AboutUs = () => {
  return (
    <div className={styles.wrapper} id="about_us">
      <h2>O nas</h2>
      <div className={styles.aboutUsWrapper} >
      <div className={styles.aboutUs}>
        <div className={styles.photo}>
          <img src={monika} alt="Monika"></img>
        </div>
        <div className={styles.about_inf}>
          <h3>Cześć, jestem Monika</h3>
          <p>Swoją przygodę z kosmetologią rozpoczęłam w 2009 roku na Wyższej Szkole Zdrowia i Urody w Gdyni. Od 2012 roku jestem licencjonowanym kosmetologiem. Rok po ukończeniu studiów otworzyłam własny salon kosmetyczny w Starogardzie Gdańskim. Jestem kosmetologiem nie tylko z wykształcenia ,ale również z zamiłowania.
          Do każdego Klienta podchodzę indywidualnie i oferuję wysoki komfort usług. Kosmetologia to moja pasja, zatem ciągle zdobywam więdzę podążając za trendami oraz szkoląc się.</p>
        </div>
      </div>
      <div className={styles.aboutUs}>
        <div className={styles.photo}>
          <img src={izabela} alt="Izabela"></img>
        </div>
        <div className={styles.about_inf}>
          <h3>Cześć, jestem Izabela</h3>
          <p>Jestem dyplomowaną kosmetyczka. W zawodzie pracuje od 2005 roku. Praca z ludzmi to dla mnie ogromna przyjemność. Staram sie aby Klienci w gabinecie czuli się swobodnie i byli zadowoloni z wykonanego zabiegu. Sepcjalizuję się w zabiegach z zakresu: podologii, stylizacji paznokci, pielegnacji twarzy, przedłużania rzęs.
          </p>
        </div>
      </div>
      </div>
    </div>
  )
}
export default AboutUs
