
import AboutUs from "../sections/AboutUs/AboutUs";
import Contact from "../sections/Contact/Contact";
import Header from "../sections/Header/Header";
import Modal from "../sections/Modal/Modal";
import Offers from "../sections/Offers/Offers";
import PermanentMakeupSection from "../sections/PermanentMakeupSection/PermanentMakeupSection";
const Home = () => {
  return (
    <>
      <Modal></Modal>
      <Header></Header>
      <AboutUs></AboutUs>
      <Offers></Offers>
      <PermanentMakeupSection />
      <Contact></Contact>
    </>
  )
}

export default Home
