import React from "react";
import styles from "../PermanentMakeupSection/PermanentMakeupSection.module.scss"
import { permanentMakeUpImages } from "../Images/ImagesData";

const PermanentMakeupSection = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.permanentMakeupSection}>
        <h2>Makijaż Permanentny Brwi</h2>
      <div className={styles.permanentDescr}>
          <p>
            Makijaż permanentny brwi jest doskonałą alternatywą dla wszystkich
            kobiet, które nie chcą lub nie mogą codziennie ich malować.<br></br>
            Osoby, które decydują się na zabieg makijażu permanentnego brwi ,
            często robią go nie tylko, aby zaoszczędzić czas, lecz także by
            poprawić naturalny kształt brwi{" "}
          </p>
          <a href="/oferta#makeup"><button>Poznaj ofertę</button> </a>
        </div>
      <div className={styles.permanentImages}>
        {permanentMakeUpImages.map((item) => (
          <div className={styles.images}>
            <img
              src={item.img}
              alt={item.title}
              width={item.width}
              height={item.height}
            />
          </div>
        ))}
        </div>
      </div>
    </div>
  );
};

export default PermanentMakeupSection;
